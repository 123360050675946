
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import AuthService from "../../services/auth-service";
import UserManagementService from "@/services/user-management"
@Component({
  components: { Header },
})
export default class AuthAccount extends Vue {
  data: any = { username: null, email: null, firstname: null, lastname: null };
  basic: any = {}
  public selected_screens: any = [];
  retrieve() {
    AuthService.getauth()
      .then((response) => {
        this.data = response.data[0];
      })
      .catch((e) => {
        console.log(e);
      });
  }
  // retrieve() {
  //   UserManagementService.getUserProfile()
  //     .then((res) => {
  //       this.basic = res
  //     })
  // }
  mounted() {
    this.retrieve()
  }

}
